import { html, render, idbkv } from "./lib/index.js";
import App from "./components/app.js";

(function () {
    let updated = false;
    let activated = false;

    if (!'serviceWorker' in navigator) {
        return;
    }

    navigator.serviceWorker.addEventListener('controllerchange', () => {
        updated = true;
        checkUpdate();
    });

    navigator.serviceWorker.addEventListener("message", (event) => {
        const data = event.data;

        window.dispatchEvent(new CustomEvent("pushMessageReceived", {
            detail: data,
        }));
    });

    idbkv.get("notification").then((data) => {
        if (data) {
            window.notificationData = data;
        }
        return idbkv.del("notification");
    });

    navigator.serviceWorker.register(
        new URL('service-worker.js', import.meta.url),
        {type: 'module'}
    ).then((registration) => {
        registration.addEventListener("updatefound", () => {
            const worker = registration.installing;
            worker.addEventListener('statechange', () => {
                if (worker.state === "activated") {
                    activated = true;
                    checkUpdate();
                }
            });
        });
    }).catch(console.error);

    function checkUpdate() {
        if (activated && updated) {
            window.location.reload();
        }
    }
})();

render(html`<${App}/>`, document.body);
